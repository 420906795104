<template>
  <base-section id="theme-features">
    <v-container
      class="d-flex flex-column align-center justify-center"
      fluid
    >
      <v-row class="d-flex flex-column align-center justify-center">
        <base-section-heading :title="$i18n.t('title')">
          {{ $i18n.t('subtitle') }}
        </base-section-heading>
      </v-row>
    </v-container>
    <v-container class="d-flex flex-column align-center justify-center">
      <v-row class="d-flex flex-column align-center justify-center">
        <v-col
          v-for="(feature, i) in fullFeatures"
          :key="i"
          cols="12"
          md="12"
        >
          <base-avatar-card
            v-bind="feature"
            align="left"
            horizontal
          >
            {{ feature.text }}
          </base-avatar-card>
          <v-expansion-panels v-if="i === 1">
            <v-expansion-panel
              v-for="(check, j) of checks"
              :key="j"
            >
              <v-expansion-panel-header>
                <p>{{ check.date }}</p>
              </v-expansion-panel-header>
              <v-expansion-panel-content
                eager
              >
                <h1>{{ check.title }} </h1>
                <br>
                <v-row>
                  <div
                    v-for="(attachment, h) of check.attachments"
                    :key="h"
                    class="d-flex align-start justify-center flex-column"
                  >
                    <p style="padding-left: 16px">
                      {{ attachment.description }}
                    </p>
                    <a
                      :href="attachment.file"
                      target="blank"
                    >
                      <v-img
                        :src="require(`@/assets/filetypes/pdf.svg`)"
                        type="image/svg+xml"
                        width="150px"
                      />
                    </a>
                  </div>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-expansion-panels v-else>
            <v-expansion-panel
              v-for="(test, k) of tests"
              :key="k"
              eaf
            >
              <v-expansion-panel-header>
                <p>{{ test.date }}</p>
              </v-expansion-panel-header>
              <v-expansion-panel-content eager>
                <v-row>
                  <v-col>
                    <div class="d-flex justify-center align-center flex-column">
                      <v-img
                        :src="test.file"
                        max-width="300px"
                      />
                      <br>
                      <p class="success--text">
                        <v-icon
                          color="success"
                          medium
                        >
                          mdi-check
                        </v-icon> Test superato
                      </p>
                    </div>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionThemeFeatures',

    data: () => ({
      checks:
        [
          {
            title: 'Prima della sanificazione',
            date: 'Oro Beach - Prima della sanificazione',
            attachments: [
              {
                description: 'Prelievo',
                file: 'https://storage.googleapis.com/sanybeach-public-assets/analisi-microbiologiche/Oro-beach-1',
              },
              {
                description: 'Rapporto di prova',
                file: 'https://storage.googleapis.com/sanybeach-public-assets/analisi-microbiologiche/Oro-beach-2',
              },
            ],
          },
          {
            title: 'Dopo la sanificazione',
            date: 'Oro beach - Dopo la sanificazione',
            attachments: [
              {
                description: 'Prelievo',
                file: 'https://storage.googleapis.com/sanybeach-public-assets/analisi-microbiologiche/Oro-beach-3',
              },
              {
                description: 'Rapporto di prova',
                file: 'https://storage.googleapis.com/sanybeach-public-assets/analisi-microbiologiche/Oro-beach-4',
              },
            ],
          },
          {
            title: 'Prima della sanificazione',
            date: 'Platinum Faro Beach - Prima della sanificazione',
            attachments: [
              {
                description: 'Prelievo',
                file: 'https://storage.googleapis.com/sanybeach-public-assets/analisi-microbiologiche/Platinum-3',
              },
              {
                description: 'Rapporto di prova',
                file: 'https://storage.googleapis.com/sanybeach-public-assets/analisi-microbiologiche/Platinum-2',
              },
            ],
          },
          {
            title: 'Dopo la sanificazione',
            date: 'Platinum Faro Beach - Dopo la sanificazione',
            attachments: [
              {
                description: 'Prelievo',
                file: 'https://storage.googleapis.com/sanybeach-public-assets/analisi-microbiologiche/Platinum-1',
              },
              {
                description: 'Rapporto di prova',
                file: 'https://storage.googleapis.com/sanybeach-public-assets/analisi-microbiologiche/Platinum-4',
              },
            ],
          },
        ],
      tests: [
        {
          date: '21/07/2020',
          file: 'https://storage.googleapis.com/sanybeach-public-assets/test/test-sanificazione-01.png',
        },
        {
          date: '18/06/2020',
          file: 'https://storage.googleapis.com/sanybeach-public-assets/test/test-sanificazione-02.png',
        },
        {
          date: '14/05/2020',
          file: 'https://storage.googleapis.com/sanybeach-public-assets/test/test-sanificazione-03.png',
        },
      ],
      features: [
        {
          color: 'primary',
          dark: true,
          icon: 'fas fa-fill-drip',
        },
        {
          color: 'primary',
          dark: true,
          icon: 'fas fa-virus-slash',
        },
      ],
    }),

    computed: {
      fullFeatures () {
        return this.features.map((feature, i) => {
          feature.title = this.$i18n.t(`t${i}.title`)
          feature.text = this.$i18n.t(`t${i}.text`)
          return feature
        })
      },
    },
  }
</script>

<i18n>
{
	"en": {
		"subtitle": "Periodically SGD Group specialized technicians verify the effective execution of the sanitisations by means of tests that allow to carry out a qualitative evaluation of the treatment. In addition, through the microbiological analysis of swabs on surfaces, the presence of microorganisms such as bacteria, mold and yeasts is verified.",
		"t0": {
			"text": "The analysis of the sanitization is carried out periodically (using Peroxide Test Strips) in order to verify the presence of the sanitizing product",
			"title": "sanitization test"
		},
		"t1": {
			"text": "Thanks to a specific analysis on surfaces, the correct sanitisation can be verified and, if so, proceed with the corrective actions envisaged",
			"title": "Random swab tests to control the sanitisation"
		},
		"title": "Verification of sanitisations and microbiological analysis"
	},
	"it": {
		"subtitle": "Periodicamente tecnici specializzati di SGD Group verificano l’effettiva esecuzione delle sanificazioni tramite test che permette di effettuare una valutazione qualitativa del trattamento. Inoltre, tramite l’analisi microbiologica di tamponi su superfici, di microrganismi quali batteri, lieviti e muffe.",
		"t0": {
			"text": "Periodicamente si effettua un test (tramite apposite strisce - Peroxide Test Strip) per verificare la corretta concentrazione del prodotto sanificante",
			"title": "Test per sanificazione"
		},
		"t1": {
			"text": "Grazie a una specifica analisi su superfici si può verificare la corretta sanificazione ed in caso di positività, procedere con le azioni correttive previste",
			"title": "Tamponi a campione per il controllo della sanificazione"
		},
		"title": "Verifica sanificazioni e analisi microbiologica"
	}
}
</i18n>
